.h-400p {
    min-height: 400px;
}

.smol {
    font-size: 0.8rem;
}

.h-10-r {
    min-height: 10rem;
}

.admin-col {
    white-space: nowrap; 
    overflow: hidden; 
    text-overflow: ellipsis; 
}

.admin-col-sm {
    max-width:6rem;
}

.admin-col-md {
    max-width:10rem;
}

.admin-col-lg {
    max-width:20rem;
}