.bg-muted {
    background: $muted;
}

/*
body {
    background-image: linear-gradient(180deg, rgb(177, 47, 47), rgb(46, 54, 165) 100px, rgb(44, 194, 39));
  }
*/
.main-bg {
    background-image: url("/img/caravan.jpeg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    position: relative;
    align-items: center;
    justify-content: center;
}

.main-bg::before {
    content: "";
    position: absolute;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
    background-color: rgba(114, 114, 114, 0.55);
}
