// STYLE OVERRIDES

//////////////////////
// Colours

$body-bg: #f7f7ff;
$body-color:#313539;
$primary: #1D0A6B;
$secondary: #0896fe;
$success: #62A87C;
$warning: #FFEF00;
$danger: #D10000;
$orange: #fea501;
$dark: #313539;
$muted: #7D869C;
$light: #f7f7ff;
$white: #ffffff;
$transparent: transparent;
$secondary-50: rgba($secondary, .5); // used for hover effect on nav items


//////////////////////
// Navbar

$navbar-light-color: $dark;
$navbar-light-hover-color: $primary;
$navbar-light-active-color: $primary;
$navbar-light-link-hover-decoration: underline;
$navbar-light-disabled-color: $muted;
$navbar-light-toggler-border-color: $transparent;


///////////////////////
// Cards

$card-cap-bg: $white;
$card-border-width: 0px;


////////////////////////
// Fonts

$font-family-sans-serif: "Lato", sans-serif;


////////////////////////
// Links

$link-color: $dark;
$link-hover-color: $primary;
$link-decoration: none;


////////////////////////
// Lists

$list-group-bg: $transparent;
$list-group-hover-bg: $secondary-50;
$list-group-active-color: $secondary;
$list-group-active-bg: $transparent;
$list-group-active-border-color: $secondary;
$list-group-action-active-bg: $secondary-50;


////////////////////////
// Dropdowns

$dropdown-border-width: 0px;
$dropdown-link-color: $dark;
$dropdown-link-hover-color: $dark;
$dropdown-link-hover-bg: $secondary-50;

////////////////////////
// MODAL

$btn-close-color: $white;
$btn-close-opacity:          1;
$btn-close-hover-opacity:    1;