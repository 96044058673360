.list-group-item-custom {
    background-color: $primary;
    color: $white;
    border-right: 0px;

    &:not(:last-child) {
        border-bottom: 1px solid $muted;
    }

    &.active {
        background-color: #fff;
        border-right: 0px;
    }
}
