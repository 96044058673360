.logo-list {
    padding: 0.8rem 0;
    text-align: center;
}
.logo-list img {
    display: inline-block;
    max-width: 85%;
    padding: 0.7rem 0;
    transition: all 0.3s ease-in-out;
    -webkit-filter: grayscale(100);
    -moz-filter: grayscale(100);
    filter: grayscale(100);
}
.logo-list img:hover {
    filter: none;
    transform: scale(1.1);
    -webkit-filter: none;
    -moz-filter: none;
}