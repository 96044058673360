.btn-secondary {
    color: $white;
}

#upButton {
    position: fixed;
    z-index: 99;
    bottom: 20px;
    right: 20px;
    display: none;
    width: 52px;
    height: 52px;
    border: none;
    border-radius: 50%;
    outline: none;
    background-color: #44434a;
    cursor: pointer;
}

#upButton:hover {
    background-color: #1d1d21;
    }