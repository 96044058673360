.card-footer {
    padding: 0.5rem 1rem 1rem 1rem;
}

.card {
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
}

.product-cards .card-body{
    background-color: $orange;
    font-size: 1.1rem;
    font-weight: bold;
    transition: all 0.2s ease-in-out;
    background-size: contain;
    background-origin: content-box;
    color: $light;
}

.product-cards .card-body:hover {
    background-color: $light;
    transition: all 0.2s ease-in-out;
    background-size: contain;
    background-origin: content-box;
    color: $orange;
}

.home-cards .card-body {
    transition: all 0.2s ease-in-out;
    background-size: contain;
    background-origin: content-box;
    height: 11rem;
    font-size: 1.1rem;
    font-weight: bold;
    color: $light;
}

.home-cards .card-body:hover {
    transition: all 0.2s ease-in-out;
    background-size: contain;
    background-origin: content-box;
    height: 11rem;
    color: $orange;
}

.touring-card {
    height: 11rem;
    padding: 1rem;
    background: url(/img/cards/touring-caravan-white.png) $orange no-repeat center top;
}

.touring-card:hover {
    padding: 1rem;
    background: url(/img/cards/touring-caravan-orange.png) $light no-repeat center top;
}

.static-card {
    height: 11rem;
    background: url(/img/cards/static-home-white.png) $orange no-repeat center top;
}

.static-card:hover {
    background: url(/img/cards/static-home-orange.png) $light no-repeat center top;
}

.trailer-tent-card {
    height: 11rem;
    background: url(/img/cards/trailer-tent-white.png) $orange no-repeat center top;
}

.trailer-tent-card:hover {
    background: url(/img/cards/trailer-tent-orange.png) $light no-repeat center top;
}

.park-home-card {
    height: 11rem;
    background: url(/img/cards/park-home-white.png) $orange no-repeat center top;
}

.park-home-card:hover {
    background: url(/img/cards/park-home-orange.png) $light no-repeat center top;
}

.trailer-card {
    height: 11rem;
    background: url(/img/cards/trailer-white.png) $orange no-repeat center top;
}

.trailer-card:hover {
    background: url(/img/cards/trailer-orange.png) $light no-repeat center top;
}

.breakdown-card {
    height: 11rem;
    background: url(/img/cards/breakdown-white.png) $orange no-repeat center top;
}

.breakdown-card:hover {
    background: url(/img/cards/breakdown-orange.png) $light no-repeat center top;
}
